import React, {useState, useEffect} from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';
import { customerService, authService } from '../../_services'
import TextareaAutosize from 'react-textarea-autosize';

const ReferencePhotoForm = ({data, handleChange, handleSubmit, validated}) => {

	const [customers, setCustomers] = useState([])
    const {t} = useTranslation('common');

    useEffect(() => {
		if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        customerService.list().then(customers => {
	            customers = customers.filter(customer => customer.products.find(product => product.name == "REFERENCE_PHOTO"))
	            setCustomers(customers)
	            if (!data.customer && customers.length > 0) {
	                handleChange({target: {type: "select", name: "customerId", value: customers[0].id}})
	            }
	        })
        }
    }, [])

    function onChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                let binaryString = event.target.result;
                let hexString = btoa(binaryString)
                handleChange({target: {type: "file", name: "data", value: hexString}})
            });
            reader.readAsBinaryString(file)
        }
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
                <Form.Group controlId="customer">
                    <Form.Label>
	                    {t('referencephoto.columns.customer')}
	                </Form.Label>
					<Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleChange} disabled={data.dateCreated}>
	                    {customers.map(customer => (
	                        <option key={customer.id} value={customer.id}>{customer.name}</option>
	                    ))}
	                </Form.Control>
                </Form.Group>
            }
            <Form.Group controlId="name">
                <Form.Label>
                    {t('referencephoto.columns.name')}
                </Form.Label>
                <Form.Control required type="text" placeholder={t('referencephoto.columns.name')} name="name" value={data.name} onChange={handleChange} />
            </Form.Group>
            <Form.Group controlId="description">
                <Form.Label>
                    {t('referencephoto.columns.description')}
                </Form.Label>
                <TextareaAutosize className="form-control" placeholder={t('referencephoto.columns.description')} name="description" value={data.description} onChange={handleChange}/>
            </Form.Group>
            <Form.Group controlId="data">
                <Form.Label>
                    {t('referencephoto.columns.file')} (.jpg)
                </Form.Label>
                { data.data &&
                    <div>
                        <img src={"data:image/jpg;base64, " + data.data} style={{maxWidth: '200px'}}/>
                    </div>
                }
                <Form.Control required={!data.data && !data.hasData} type="file" accept=".jpg" name="data" onChange={onChange} />
                <Form.Control.Feedback type="invalid">
                    {t('referencephoto.errors.fileinvalid')}
                </Form.Control.Feedback>
            </Form.Group>
        </Form>
    )
}


export default ReferencePhotoForm