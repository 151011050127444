import React, { useEffect, useState } from 'react';
import { SERVER_URL } from '../../config';
import { Button, Modal, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { useParams } from 'react-router-dom';
import { authService, documentationGroupService, templateService, photoService, documentationTaskService } from '../../_services'
import moment from 'moment'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'

function DocumentationGroupDetails() {

	const {documentationName} = useParams();
	const [documentations, setDocumentations] = useState([]);
	const [documentationTasks, setDocumentationTasks] = useState([]);
	const [templates, setTemplates] = useState([])
	const [photos, setPhotos] = useState([]);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [show, setShow] = useState(false);
	const [backdrop, setBackdrop] = useState(false);
	const [alias, setAlias] = useState('');
    const {t} = useTranslation('common');
    const dateFormat = 'yyyy-MM-DD HH:mm:ss'

    useEffect(() => {
		documentationGroupService.findAllByDocumentationName(documentationName, 50).then(data => {
            setDocumentations(data)
            if (data.length > 0) {
                let alias = data[0].alias
                if (alias != documentationName) {
                    setAlias(alias)
                }
            }
        })

		documentationTaskService.findAllByDocumentationName(documentationName, 1000).then(documentationTasks => {
            setDocumentationTasks(documentationTasks)

            photoService.findAllByDocumentationName(documentationName, 1000).then(data => {
                data.forEach(photo => {
                    photo.taskPosition = -1
                    var task = documentationTasks.find(task => task.id == photo.documentationTaskId)
                    if (task) {
                        photo.taskPosition = task.position
                    }
                })

                data.sort((a, b) => a.taskPosition - b.taskPosition || a.name.localeCompare(b.name))

	            setPhotos(data)
	        })
        })

        templateService.list(50).then(data => {
            data.sort((a, b) => {
                if (a.position != b.position) {
                    return a.position - b.position
                }
                return a.name.localeCompare(b.name)
            })
            setTemplates(data)
        })
    }, [])

    const onClick = (documentation, photo) => {
        console.log('onClick')
        var carouselPhotos = getCarouselPhotos()
        setPhotoIndex(carouselPhotos.indexOf(photo))
        setShow(true)
    }

    const onHide = () => {
		setShow(false)
		setBackdrop(false)
    }

    const renderItem = (item, options) => {
        if (options && options.isSelected) {
			return item
        }
        return <></>
    }

    const getCarouselPhotos = () => {
        var carouselPhotos = []
        templates.forEach(template => {
			var filteredDocumentations = documentations.filter(documentation => documentation.templateId == template.id)
			filteredDocumentations.forEach(documentation => {
				var filteredPhotos = photos.filter(photo => photo.documentationId == documentation.id)
				filteredPhotos.forEach(photo => {
					carouselPhotos.push(photo)
				})
			})
        })
        return carouselPhotos
    }

    const downloadZipFile = () => {
		let documentation = documentations[0]
		let userId = authService.getCurrentUser().id
		window.location.href = SERVER_URL + '/documentationGroup/downloadZipFile/' + documentation.id + '?userId=' + userId
    }

	return (
		<div>
			<div className="clearfix">
				<div className="float-left">
					<h1>
						{documentationName} {alias ? '(' + alias + ')' : ''}
					</h1>
	            </div>
				<div className="float-right">
					<Button variant="outline-dark" onClick={downloadZipFile}>
						<FontAwesomeIcon icon={faFileExport} style={{color: 'gray'}}/> {t('documentationgroup.downloadzipfile')}
					</Button>
				</div>
			</div>
				<div>
				{ templates.map(template => {
					var filteredDocumentations = documentations.filter(documentation => documentation.templateId == template.id)
					if (filteredDocumentations) {
						return filteredDocumentations.map(documentation => {
							var filteredPhotos = photos.filter(photo => photo.documentationId == documentation.id)
							return <div key={documentation.id}>
								<Row style={{marginTop: '20px'}}>
									<Col md="12">
										<h3 style={{marginBottom: '0px'}}>{template.name} ({moment(documentation.dateCreated).format('yyyy-MM-DD')})</h3>
									</Col>
									{ filteredPhotos.map(photo => {
					                    return <Col lg="2" md="3" sm="4" xs="6" key={photo.id}>
					                        <div style={{paddingTop: '20px'}}>
						                        {photo.alias || photo.name}
					                        </div>
											<a onClick={() => onClick(documentation, photo)} style={{cursor: 'pointer'}}>
						                        <img src={SERVER_URL + '/photo/thumbnail/' + photo.id} style={{width: '100%'}}/>
						                    </a>
						                    <br/>
						                    {photo.comment ? '(' + photo.comment + ')' : ''}
					                    </Col>
					                })}
				                </Row>
							</div>
						})
					}
					return <></>
				})}
			</div>
			<div style={{height: '50px'}}>
			</div>
			<Modal
				style={{overflow: 'scroll'}}
				size="xl"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Carousel selectedItem={photoIndex} showStatus={false} showThumbs={false} renderItem={renderItem}>
						{ getCarouselPhotos().map(photo => {
							return <Row key={photo.id}>
								<Col sm="12" style={{textAlign: 'left'}}>
									<h2>{photo.alias || photo.name}</h2>
								</Col>
								<Col>
									<a href={SERVER_URL + '/photo/download/' + photo.id} target="_blank">
										<img src={SERVER_URL + '/photo/download/' + photo.id} style={{maxWidth: '100%', pointerEvents: 'auto'}}/>
									</a>
								</Col>
								<Col sm="3" style={{textAlign: 'left'}}>
									<ul style={{listStyleType: 'none', paddingLeft: 0}}>
										<li>
											<b>{t('photo.columns.comment')}</b>
										</li>
										<li>
											{photo.comment || '-'}
										</li>
										<li>
											<b>{t('photo.columns.datecreated')}</b>
										</li>
										<li>
											{moment(photo.dateCreated).format(dateFormat)}
										</li>
										<li>
											<b>{t('photo.columns.dateuploaded')}</b>
										</li>
										<li>
											{moment(photo.dateUploaded).format(dateFormat)}
										</li>
										{ photo.lat != 1000 && photo.lng != 1000 &&
											<>
												<li>
													<b>{t('photo.columns.position')}</b>
												</li>
												<li>
													<a href={'https://www.google.com/maps/search/?api=1&query=' + photo.lat + '%2C' + photo.lng} target="_blank">
														{photo.lat}, {photo.lng}
													</a>
												</li>
											</>
										}
									</ul>
								</Col>
							</Row>
		                })}
					</Carousel>
				</Modal.Body>
			</Modal>
        </div>
    )
}

export default DocumentationGroupDetails;