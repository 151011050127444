import React, { useState, useEffect } from 'react'
import { Form, Button, Row, Col, Accordion, Card } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faGripVertical, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import TextareaAutosize from 'react-textarea-autosize';
import { v4 as uuidv4 } from 'uuid';
import { suggestionListService, authService, customerService, licenseService } from '../../_services'
import Select from 'react-select'

const SuggestionListForm = ({data, handleChange, handleSubmit, validated}) => {

	const [suggestionLists, setSuggestionLists] = useState([])
	const [customers, setCustomers] = useState([])
	const [suggestionListLicenses, setSuggestionListLicenses] = useState([])
	const [selectedSuggestionListLicenses, setSelectedSuggestionListLicenses] = useState([])
	const [referenceSystem, setReferenceSystem] = useState("WGS84")
    const {t} = useTranslation('common');

    useEffect(() => {
        if (authService.hasRoles(['ROLE_SUPER_ADMIN'])) {
	        customerService.list().then(data => {
	            data.sort((a, b) => {
	                return a.name.localeCompare(b.name)
	            })
	            setCustomers(data)
	        })
        }
        suggestionListService.list().then(suggestionLists => {
            suggestionLists.sort((a, b) => {
                if (a.name != b.name) {
                    return a.name.localeCompare(b.name)
                }
                return a.customerName.localeCompare(b.customerName)
            })
			setSuggestionLists(suggestionLists)
        })
        if (authService.hasRoles(['ROLE_LICENSE_READ'])) {
	        licenseService.simpleList().then(licenses => {
	            var suggestionListLicenses = []
	            var selectedSuggestionListLicenses = []
	            licenses.forEach(license => {
	                var suggestionListLicense = data.suggestionListLicenses.find(suggestionListLicense => suggestionListLicense.license == license.id)
	                if (suggestionListLicense) {
	                    suggestionListLicense.name = license.name
						suggestionListLicense.licenseCode = license.licenseCode
						suggestionListLicense.customerId = license.customerId
						selectedSuggestionListLicenses.push(suggestionListLicense)
	                } else {
	                    suggestionListLicense = {
							license: license.id,
							name: license.name,
							licenseCode: license.licenseCode,
							customerId: license.customerId
						}
	                }
	                suggestionListLicenses.push(suggestionListLicense)
	            })
	            setSuggestionListLicenses(suggestionListLicenses)
	            setSelectedSuggestionListLicenses(selectedSuggestionListLicenses)
	        })
        }
    }, [])

    function addSuggestion(e) {
        e.preventDefault();

        var suggestion = {
            id: uuidv4(),
            customerId: data.customerId,
            text: '',
            imported: false,
            lat: 1000,
            lng: 1000
        }

        let newSuggestions = [...data.suggestions, suggestion]

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})

		setTimeout(() => {
			let element = document.getElementById("suggestion-table")
            element.scrollTop = element.scrollHeight
		}, 500)
    }

    function removeSuggestion(suggestion, e) {
        e.preventDefault();

        var newSuggestions = data.suggestions.filter(t => t.id !== suggestion.id)

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
    }

    function removeAll(e) {
        e.preventDefault();

        let newSuggestions = []

        handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
    }

    function handleCustomerChange(e) {
        var customerId = e.target.value
        data.suggestions.forEach(suggestion => suggestion.customerId = customerId)
		handleChange(e)
    }

    function handleSuggestionListChange(e) {
        var suggestionList = suggestionLists.find(suggestionlist => suggestionlist.id == e.target.value)
        handleChange({target: {type: "text", name: "name", value: suggestionList.name}})
        handleChange({target: {type: "text", name: "description", value: suggestionList.description}})
        handleChange({target: {type: "text", name: "type", value: suggestionList.type}})
        var suggestions = []
        suggestionList.suggestions.forEach(suggestion => {
            var suggestionsCopy = {
                ...suggestion,
	            id: uuidv4(),
	            customerId: data.customerId,
	            dateCreated: null,
	            lastUpdated: null
	        }
	        suggestions.push(suggestionsCopy)
        })
        handleChange({target: {type: "object", name: "suggestions", value: suggestions}})
    }

    function handleSuggestionChanges(suggestion, e) {
        var value = e.target.value
        if (e.target.name == 'text') {
            var invalidChars = ['~', '"', '#', '%', '&', '*', ':', '<', '>', '?', '/', '\\', '{', '|', '}']
	        invalidChars.forEach(invalidChar => value = value.replaceAll(invalidChar, ''))
        } else if (e.target.name == 'lat' || e.target.name == 'lng') {
            value = parseFloat(value)
        }
        suggestion[e.target.name] = value
        handleChange({target: {type: "object", name: "suggestions", value: data.suggestions}})
    }

    function handleFileChange(e) {
        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                const result = reader.result
                var rows = result.split(/\r?\n/)
                var newSuggestions = [...data.suggestions]
                var existingSuggestions = newSuggestions.map(suggestion => suggestion.text)
                rows.forEach(row => {
                    if (row) {
                        var rowSplit = row.split(';')
                        console.log(rowSplit)
                        if (!existingSuggestions.includes(rowSplit[0])) {
		                    var suggestion = {
					            id: uuidv4(),
					            customerId: data.customerId,
					            text: rowSplit[0],
					            lat: 1000,
					            lng: 1000
					        }
					        if (rowSplit.length == 3) {
					            if (referenceSystem == "RT90") {
					                var y = parseInt(rowSplit[1])
					                var x = parseInt(rowSplit[2])
					                if (x && y) {
					                    const converted = convertCoordinates(x, y)
					                    console.log(converted)
				                        suggestion.lat = converted.lat
				                        suggestion.lng = converted.lng
					                }
				                } else {
				                    var lat = parseFloat(rowSplit[1])
					                var lng = parseFloat(rowSplit[2])
					                if (lat) {
					                    suggestion.lat = lat
					                }
				                    if (lng) {
				                        suggestion.lng = lng
				                    }
				                }
					        }
					        newSuggestions.push(suggestion)
					        existingSuggestions.push(suggestion)
	                    } else if (rowSplit.length == 3) {
	                        var suggestion = newSuggestions.find(suggestion => suggestion.text == rowSplit[0])

	                        if (referenceSystem == "RT90") {
				                var y = parseInt(rowSplit[1])
				                var x = parseInt(rowSplit[2])
				                if (x && y) {
				                    const converted = convertCoordinates(x, y)
				                    console.log(converted)
			                        suggestion.lat = converted.lat
			                        suggestion.lng = converted.lng
				                }
			                } else {
			                    var lat = parseFloat(rowSplit[1])
				                var lng = parseFloat(rowSplit[2])
				                if (lat) {
				                    suggestion.lat = lat
				                }
			                    if (lng) {
			                        suggestion.lng = lng
			                    }
			                }
	                    }
                    }
                })
                handleChange({target: {type: "object", name: "suggestions", value: newSuggestions}})
            });
            reader.readAsText(file)
        }
    }

    const isDuplicate = suggestion => {
        var otherSuggestions = data.suggestions.filter(otherSuggestion => otherSuggestion.text.trim() === suggestion.text.trim())
        return otherSuggestions.length > 1
    }

    const convertCoordinates = (x, y) => {
        return gridToGeodetic(x, y)
    }

    const gridToGeodetic = (x, y): GeodeticCoordinates => {

        const params = {
            axis: 6378137.0, // GRS 80.
            flattening: 1.0 / 298.257222101, // GRS 80.
            centralMeridian: 15.0 + 48.0 / 60.0 + 22.624306 / 3600.0,
            latOfOrigin: 0.0,
            scale: 1.00000561024,
            falseNorthing: -667.711,
            falseEasting: 1500064.274
		}

		// Prepare ellipsoid-based stuff.
		const e2 = params.flattening * (2.0 - params.flattening)
		const n = params.flattening / (2.0 - params.flattening)
		const aRoof = params.axis / (1.0 + n) * (1.0 + n ** 2 / 4.0 + n ** 4 / 64.0)
		const delta1 = n / 2.0 - 2.0 * n ** 2 / 3.0 + 37.0 * n ** 3 / 96.0 - n ** 4 / 360.0
		const delta2 = n ** 2 / 48.0 + n ** 3 / 15.0 - 437.0 * n ** 4 / 1440.0
		const delta3 = 17.0 * n ** 3 / 480.0 - 37 * n ** 4 / 840.0
		const delta4 = 4397.0 * n ** 4 / 161280.0
		const Astar = e2 + e2 ** 2 + e2 ** 3 + e2 ** 4
		const Bstar = -(7.0 * e2 ** 2 + 17.0 * e2 ** 3 + 30.0 * e2 ** 4) / 6.0
		const Cstar = (224.0 * e2 ** 3 + 889.0 * e2 ** 4) / 120.0
		const Dstar = -(4279.0 * e2 ** 4) / 1260.0
		// Convert.
		const degToRad = Math.PI / 180
		const lambdaZero = params.centralMeridian * degToRad
		const xi = (x - params.falseNorthing) / (params.scale * aRoof)
		const eta = (y - params.falseEasting) / (params.scale * aRoof)
		const xiPrim = xi - delta1 * Math.sin(2.0 * xi) * Math.cosh(2.0 * eta) - delta2 * Math.sin(4.0 * xi) * Math.cosh(
            4.0 * eta
        ) - delta3 * Math.sin(6.0 * xi) * Math.cosh(6.0 * eta) - delta4 * Math.sin(8.0 * xi) * Math.cosh(
            8.0 * eta)
        const etaPrim = eta - delta1 * Math.cos(2.0 * xi) * Math.sinh(2.0 * eta) - delta2 * Math.cos(
            4.0 * xi
        ) * Math.sinh(4.0 * eta) - delta3 * Math.cos(6.0 * xi) * Math.sinh(6.0 * eta) - delta4 * Math.cos(
            8.0 * xi) * Math.sinh(8.0 * eta)
        const phiStar = Math.asin(Math.sin(xiPrim) / Math.cosh(etaPrim))
        const deltaLambda = Math.atan(Math.sinh(etaPrim) / Math.cos(xiPrim))
        const lngRadian = lambdaZero + deltaLambda
        const latRadian = phiStar + Math.sin(phiStar) * Math.cos(phiStar) * (
            Astar + Bstar * Math.pow(Math.sin(phiStar), 2) + Cstar * Math.pow(Math.sin(phiStar), 4) + Dstar * Math.pow(Math.sin(phiStar), 6)
        )
	    return {
			lat: latRadian * 180.0 / Math.PI,
			lng: lngRadian * 180.0 / Math.PI
	    }
	}

	const onSuggestionListLicenseChange = (e) => {
        if (e) {
            setSelectedSuggestionListLicenses(e)
            handleChange({target: {type: "select", name: "suggestionListLicenses", value: e}})
        } else {
            setSelectedSuggestionListLicenses([])
            handleChange({target: {type: "select", name: "suggestionListLicenses", value: []}})
        }
    }

    return (
        <Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
            <Row>
	            <Col sm="4">
	                { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
	                    <Form.Group controlId="customer">
		                    <Form.Label>
			                    {t('suggestionlist.columns.customer')}
			                </Form.Label>
							<Form.Control required as="select" name="customerId" value={data.customerId} onChange={handleCustomerChange} disabled={data.dateCreated}>
			                    {customers.map(customer => (
			                        <option key={customer.id} value={customer.id}>{customer.name}</option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
	                { !data.dateCreated &&
	                    <Form.Group controlId="suggestionlist">
		                    <Form.Label>
			                    {t('suggestionlist.copyfrom')}
			                </Form.Label>
							<Form.Control required as="select" name="suggestionlist" value={-1} onChange={handleSuggestionListChange} disabled={suggestionLists.length == 0}>
								<option value={-1}>{t('suggestionlist.choosesuggestionlist')}</option>
			                    { suggestionLists.map(suggestionList => (
			                        <option key={suggestionList.id} value={suggestionList.id}>
			                            {suggestionList.name}
			                            { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
			                                ' (' + suggestionList.customerName + ')'
			                            }
			                        </option>
			                    ))}
			                </Form.Control>
	                    </Form.Group>
	                }
		            <Form.Group controlId="name">
		                <Form.Label>
		                    {t('suggestionlist.columns.name')} *
		                </Form.Label>
		                <Form.Control required type="text" placeholder={t('suggestionlist.columns.name')} name="name" value={data.name} onChange={handleChange} />
		            </Form.Group>
		            <Form.Group controlId="description">
		                <Form.Label>
		                    {t('suggestionlist.columns.description')}
		                </Form.Label>
		                <TextareaAutosize className="form-control" placeholder={t('suggestionlist.columns.description')} name="description" value={data.description || ''} onChange={handleChange}/>
		            </Form.Group>
		            <Form.Group controlId="type">
	                    <Form.Label>
		                    {t('suggestionlist.columns.type')}
		                </Form.Label>
						<Form.Control required as="select" name="type" value={data.type} onChange={handleChange}>
							<option value="DOCUMENTATION_NAMES">{t('suggestionlist.types.documentationnames')}</option>
							<option value="TASK_OPTIONS">{t('suggestionlist.types.taskoptions')}</option>
		                </Form.Control>
                    </Form.Group>
                    { authService.hasRoles(['ROLE_SUPER_ADMIN']) &&
	                    <Form.Group controlId="suggestionListLicenses">
			                <Form.Label>
			                    {t('suggestionlist.columns.licenses')}
			                </Form.Label>
			                <Select
			                    placeholder={t('suggestionlist.columns.licenses')}
			                    isLoading={suggestionListLicenses.length === 0}
			                    options={suggestionListLicenses.filter(suggestionListLicense => suggestionListLicense.customerId == data.customerId)}
			                    value={selectedSuggestionListLicenses}
			                    getOptionLabel={ suggestionListLicense => {
			                        if (suggestionListLicense.name) {
			                            return suggestionListLicense.name + ' (' + suggestionListLicense.licenseCode + ')'
			                        }
			                        return suggestionListLicense.licenseCode
			                    }}
			                    getOptionValue={ suggestionListLicense => suggestionListLicense.id }
			                    isMulti={true}
			                    closeMenuOnSelect={false}
			                    onChange={onSuggestionListLicenseChange}
			                />
			            </Form.Group>
		            }
			        { data.type == 'DOCUMENTATION_NAMES' &&
			            <>
				            <Form.Group controlId="deleteSuggestionIfDocumentationExists" style={{marginBottom: 0}}>
				                <Form.Label>
				                    <Form.Check type="checkbox" name="deleteSuggestionIfDocumentationExists" label={t('suggestionlist.columns.deletesuggestionifdocumentationexists')} checked={data.deleteSuggestionIfDocumentationExists} onChange={handleChange} />
				                </Form.Label>
				            </Form.Group>
				            <Form.Group controlId="requiredDocumentationStatus">
				                <Form.Label>
				                    {t('suggestionlist.columns.requireddocumentationstatus')}
				                </Form.Label>
				                <Form.Control as="select" name="type" value={data.requiredDocumentationStatus || ''} onChange={handleChange} disabled={!data.deleteSuggestionIfDocumentationExists}>
				                    <option value="">{t('suggestionlist.nostatus')}</option>
				                    <option>ONGOING</option>
				                    <option>PAUSED</option>
				                    <option>DOWNLOADED</option>
				                    <option>COMPLETED</option>
				                    <option>DELETED</option>
				                    <option>HANDLED</option>
				                    <option>WAITING_FOR_FILE</option>
				                </Form.Control>
				            </Form.Group>
			            </>
			        }
		        </Col>
		        <Col sm="8">
		            <Form.Group controlId="suggestions">
		                <Form.Label>
		                    {t('suggestionlist.columns.suggestions')}
		                </Form.Label>
		                <div id="suggestion-table" style={{maxHeight: 480, overflowY: 'auto', marginBottom: 10}}>
							<table className="table table-bordered table-sm">
								<thead>
				                    <tr>
				                        <th>
				                            {t('suggestion.columns.name')} *
				                        </th>
				                        { data.type == 'DOCUMENTATION_NAMES' &&
				                            <>
						                        <th style={{width: 100}}>
						                            {t('suggestion.columns.lat')}
						                        </th>
						                        <th style={{width: 100}}>
						                            {t('suggestion.columns.lng')}
						                        </th>
					                        </>
				                        }
				                        <th style={{width: 20}} title={t('suggestion.columns.imported')}>
				                            {t('suggestion.columns.imp')}
				                        </th>
				                        <th style={{width: 20}}></th>
				                    </tr>
				                </thead>
								<tbody>
									{ data.suggestions.map((suggestion, index) => {
										return <tr key={suggestion.id}>
											<td>
												<Form.Control
													required
													type="text"
													placeholder={t('suggestion.columns.name')}
													name="text"
													value={suggestion.text}
													onChange={e => handleSuggestionChanges(suggestion, e)}
													pattern={isDuplicate(suggestion) ? '' : null}
												/>
											</td>
											{ data.type == 'DOCUMENTATION_NAMES' &&
												<>
													<td>
														<Form.Control required type="number" step="0.1" placeholder={t('suggestion.columns.lat')} name="lat" value={suggestion.lat} onChange={e => handleSuggestionChanges(suggestion, e)}/>
													</td>
													<td>
														<Form.Control required type="number" step="0.1" placeholder={t('suggestion.columns.lng')} name="lng" value={suggestion.lng} onChange={e => handleSuggestionChanges(suggestion, e)}/>
													</td>
												</>
											}
											<td>
												{suggestion.imported ? t('crudtable.yes') : t('crudtable.no')}
											</td>
											<td style={{textAlign: 'center', verticalAlign: 'middle'}}>
												<Button
										            onClick={(e) => removeSuggestion(suggestion, e)}
										            title={t("crudtable.delete.button")}
										            size="sm"
										            color="info"
										            variant="outline-danger">
										            <FontAwesomeIcon icon={faTrash}/>
										        </Button>
											</td>
										</tr>
									})}
								</tbody>
							</table>
						</div>
		                <Button
		                    onClick={addSuggestion}
		                    title={t('suggestionlist.addsuggestion')}
		                    color="info"
		                    variant="outline-primary">
		                    <FontAwesomeIcon icon={faPlus}/> {t('suggestionlist.addsuggestion')}
		                </Button>
		                &nbsp;
		                <Button
		                    onClick={removeAll}
		                    title={t('suggestionlist.removeall')}
		                    variant="outline-danger">
		                    <FontAwesomeIcon icon={faTrash}/> {t('suggestionlist.removeall')}
		                </Button>
                        <Accordion style={{ marginTop: 10 }}>
                            <Card>
                                <Accordion.Toggle as={Card.Header} eventKey="0" style={{cursor: 'pointer'}}>
                                    <div className="float-left">
                                        {t('suggestionlist.import.label')}
                                    </div>
                                    <div className="float-right">
                                        <FontAwesomeIcon icon={faChevronDown}/>
                                    </div>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey="0">
                                    <Card.Body>
                                        <Row>
                                            <Col md="6">
	                                            <Form.Control required as="select" name="referenceSystem" value={referenceSystem} onChange={e => setReferenceSystem(e.target.value)}>
	                                                <option value="WGS84">WGS84</option>
	                                                <option value="RT90">RT90 2.5 gon V 0:-15 (Nationell)</option>
								                </Form.Control>
	                                        </Col>
                                            <Col md="6">
	                                            <Form.Control type="file" accept=".txt,.csv" name="text" onChange={handleFileChange} />
	                                        </Col>
	                                    </Row>
	                                    <Row>
	                                        <Col md="12">
	                                            <b>{t('suggestionlist.import.columns')}:</b>
	                                            &nbsp;
	                                            { referenceSystem == "WGS84" &&
	                                                <span>name;lat;lng</span>
	                                            }
	                                            { referenceSystem == "RT90" &&
	                                                <span>name;y;x</span>
	                                            }
	                                        </Col>
	                                    </Row>
	                                </Card.Body>
	                            </Accordion.Collapse>
	                        </Card>
	                    </Accordion>
		            </Form.Group>
                </Col>
            </Row>
        </Form>
    )
}

export default SuggestionListForm