import React, { useEffect, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import CrudTable from '../table/crudtable'
import { documentationService, authService, templateService } from '../../_services'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle, faFilter, faGear, faCircleInfo, faFileExport, faPlus } from '@fortawesome/free-solid-svg-icons'
import { multiSelectFilter, customFilter, FILTER_TYPES, Comparator } from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import TemplateFilter from './templatefilter.js'
import CreatorFilter from './creatorfilter.js'
import DateFilter from './datefilter'
import StatusFilter from './statusfilter.js'
import ExportedToFilter from './exportedtofilter.js'
import DelayedSearch from './delayedsearch.js'
import { SERVER_URL } from '../../config';
import ExportButton from './list/exportbutton.js'

function DocumentationList() {

	const [count, setCount] = useState(0)
	const [roles, setRoles] = useState([])
	const [templates, setTemplates] = useState([])
	const [templatesDownloaded, setTemplatesDownloaded] = useState(false)
	const [query, setQuery] = useState('')
	const [max, setMax] = useState(500)
	const [sort, setSort] = useState('dateUploaded')
	const [order, setOrder] = useState('desc')
	const [showHidden, setShowHidden] = useState(false)
	const [templateFilterList, setTemplateFilterList] = useState([])
	const [deletedTemplates, setDeletedTemplates] = useState(true)
	const [creators, setCreators] = useState([]);
	const [creatorFilterList, setCreatorFilterList] = useState([]);
	const [uploadedDateRangeActive, setUploadedDateRangeActive] = useState(false);
	const [uploadedDateRange, setUploadedDateRange] = useState(["2021-01-01", new Date().toISOString().substring(0, 10)])
	const [createdDateRange, setCreatedDateRange] = useState(["2021-01-01", new Date().toISOString().substring(0, 10)])
	const [statusFilterList, setStatusFilterList] = useState(['DELETED', 'COMPLETED', 'PAUSED', 'DOWNLOADED', 'ONGOING', 'HANDLED', 'APPROVED', 'WAITING_FOR_FILE'])
	const [exportedToFilterList, setExportedToFilterList] = useState(['exported', 'unexported'])
	const statusSortOrder = ['DELETED', 'COMPLETED', 'PAUSED', 'DOWNLOADED', 'ONGOING', 'HANDLED', 'APPROVED', 'WAITING_FOR_FILE']
	const {t} = useTranslation('common');

	useEffect(() => {
		const currentUser = authService.getCurrentUser()

		setRoles(currentUser.roles)

		templateService.names().then(data => {
            setTemplates(data)
            const templateFilterListKey = 'templateFilterList-' + currentUser.id
            if (sessionStorage.getItem(templateFilterListKey)) {
                var split = sessionStorage.getItem(templateFilterListKey).split(',')
                setTemplateFilterList(split)
            } else {
                setTemplateFilterList(data.map(template => template.id))
            }
            setTemplatesDownloaded(true)
        })

        documentationService.getCreators().then(data => {
            setCreators(data)
            setCreatorFilterList(data)
        })

        documentationService.count().then(data => {
            setCount(parseInt(data))
        })

        setShowHidden(localStorage.getItem('showHiddenDocumentations') === "true")
        setMax(localStorage.getItem('maxDocumentations') || 500)

        const deletedTemplatesKey = 'deletedTemplates-' + currentUser.id
        if (sessionStorage.getItem(deletedTemplatesKey)) {
            setDeletedTemplates(sessionStorage.getItem(deletedTemplatesKey) === "true")
        }

        const queryKey = 'query-' + currentUser.id
        console.log(queryKey)
        console.log(sessionStorage.getItem(queryKey))
        if (sessionStorage.getItem(queryKey)) {
            setQuery(sessionStorage.getItem(queryKey))
        }
	}, [])

	useEffect(() => {
		const currentUser = authService.getCurrentUser()
		const templateFilterListKey = 'templateFilterList-' + currentUser.id
		if (templateFilterList.length > 0 && templateFilterList.length != templates.length) {
			sessionStorage.setItem(templateFilterListKey, templateFilterList.join(','))
		} else if (templateFilterList.length > 0) {
			sessionStorage.setItem(templateFilterListKey, '')
		}
	}, [templateFilterList])

	useEffect(() => {
		const currentUser = authService.getCurrentUser()
		const deletedTemplatesKey = 'deletedTemplates-' + currentUser.id
		sessionStorage.setItem(deletedTemplatesKey, '' + deletedTemplates)
	}, [deletedTemplates])

	useEffect(() => {
		if (query) {
			const currentUser = authService.getCurrentUser()
			const queryKey = 'query-' + currentUser.id
			sessionStorage.setItem(queryKey, query)
		}
	}, [query])

	const toggleShowHidden = () => {
		localStorage.setItem("showHiddenDocumentations", !showHidden);
		setShowHidden(!showHidden)
	}

	const changeMax = (newMax) => {
		localStorage.setItem("maxDocumentations", newMax);
		setMax(newMax)
	}

	const formatName = function(cell, row) {
		return <a href={'/documentation/details/' + row.id}>{row.alias || cell}</a>
	}

	const formatTemplate = function(cell) {
		var template = templates.find(template => template.id == cell)
		if (template) {
			if (template.name.includes('#') && template.name.includes('@')) {
				var index = template.name.indexOf('#')
				return <span title={template.name}>
					{template.name.substring(0, index)}
					&nbsp;
					<FontAwesomeIcon icon={faCircleInfo} style={{color: '#0d57e5'}}/>
				</span>
			}
			return template.name
		}
		return '-'
	}

	const formatCreator = function(cell, row) {
		if (row.deviceId && row.deviceId.length >= 4) {
			return cell + ' (' + row.deviceId.substring(0, 4) + ')'
		}
		return cell
	}

	const formatDate = function(cell) {
		if (cell) {
			return moment(cell).format('YYYY-MM-DD HH:mm:ss')
		}
		return '-'
	}

	const dateUploadedSortValue = function(cell, row) {
		if (cell) {
			return moment(cell).format('YYYY-MM-DD HH:mm:ss')
		}
		return '0' + moment(row.dateCreated).format('YYYY-MM-DD HH:mm:ss')
	}

	const statusSortValue = function(cell, row) {
		return statusSortOrder.indexOf(cell) + '-' + row.dateUploaded + '-' + row.dateCreated
	}

	const formatStatus = function(cell, row) {
		if (cell === 'ONGOING') {
			return t('documentation.status.ongoing') + ' (' + row.uploadedPhotos + '/' + row.numberOfPhotos + ')'
		} else if (cell === 'PAUSED') {
			return t('documentation.status.paused')
		} else if (cell === 'DOWNLOADED') {
			return t('documentation.status.downloaded')
		} else if (cell === 'HANDLED') {
			return t('documentation.status.handled')
		} else if (cell === 'APPROVED') {
			return t('documentation.status.approved')
		} else if (cell === 'WAITING_FOR_FILE') {
			return t('documentation.status.waitingforfile')
		}
		return t('documentation.status.completed')
	}

	const formatExportEvents = function(cell) {
		if (cell && cell.length > 0) {
			cell.sort((a, b) => a.dateCreated < b.dateCreated ? -1 : 1)
			if (cell.some(exportEvent => !exportEvent.success)) {
				return <>
					<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
					&nbsp;
					{cell.map(exportEvent => exportEvent.exportConfigurationName).join(", ")}
				</>
			}
			return cell.map(exportEvent => exportEvent.exportConfigurationName).join(", ")
		}
		return '-'
	}

	const onSort = (field, order) => {
		setSort(field)
		setOrder(order)
	}

	const getServiceParams = () => {
		var serviceParams = {
			showHidden: showHidden,
			sort: sort,
			order: order
		}
		if (max != -1) {
			serviceParams.max = max
		}
		if (query) {
			serviceParams.query = query
		}
		if (templateFilterList.length == 0 && !deletedTemplates) {
		} else if (templateFilterList.length != templates.length || !deletedTemplates) {
			serviceParams.templateFilter = templateFilterList.join(';')
			serviceParams.deletedTemplates = deletedTemplates
		}
		if (creatorFilterList.length != creators.length) {
			serviceParams.creatorFilter = creatorFilterList.join(';')
		}
		if (uploadedDateRangeActive) {
			serviceParams.uploadedDateRange = uploadedDateRange.join(';') + ' 23:59:59'
		}
		serviceParams.createdDateRange = createdDateRange.join(';') + ' 23:59:59'
		if (statusFilterList.length != 4) {
			serviceParams.statusFilter = statusFilterList.join(';')
		}
		if (exportedToFilterList.length != 2) {
			serviceParams.exportedFilter = exportedToFilterList.join(';')
		}
		return serviceParams
	}

	const columns = [
		{
			dataField: 'name',
			text: t('documentation.columns.name'),
			formatter: formatName,
			sort: true,
			onSort: onSort
		},
		{
			dataField: 'customerName',
			text: t('documentation.columns.customer'),
			hidden: !roles.includes('ROLE_SUPER_ADMIN')
		},
		{
			dataField: 'templateId',
			text: t('documentation.columns.template'),
			formatter: formatTemplate,
			filterValue: formatTemplate,
			sortValue: formatTemplate,
			sort: true,
			onSort: onSort,
			filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <TemplateFilter
					templates={templates}
					templateFilterList={templateFilterList}
					setTemplateFilterList={setTemplateFilterList}
					deletedTemplates={deletedTemplates}
					setDeletedTemplates={setDeletedTemplates}
				/>
			}
		},
		{
			dataField: 'creator',
			text: t('documentation.columns.creator'),
			formatter: formatCreator,
			filterValue: formatCreator,
			sort: true,
			onSort: onSort,
			filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <CreatorFilter
					creators={creators}
					creatorFilterList={creatorFilterList}
					setCreatorFilterList={setCreatorFilterList}
				/>
			}
		},
		{
			dataField: 'dateUploaded',
			text: t('documentation.columns.dateuploaded'),
			formatter: formatDate,
			sortValue: dateUploadedSortValue,
			sort: true,
			onSort: onSort,
            filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <DateFilter
					dateRange={uploadedDateRange}
					setDateRange={setUploadedDateRange}
					toggleable={true}
					active={uploadedDateRangeActive}
					setActive={setUploadedDateRangeActive}
				/>
			}
		},
		{
			dataField: 'dateCreated',
			text: t('documentation.columns.datecreated'),
			formatter: formatDate,
			sortValue: formatDate,
			sort: true,
			onSort: onSort,
            filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <DateFilter dateRange={createdDateRange} setDateRange={setCreatedDateRange}/>
			}
		},
		{
			dataField: 'status',
			text: t('documentation.columns.status'),
			formatter: formatStatus,
			sortValue: statusSortValue,
			sort: true,
			onSort: onSort,
			filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <StatusFilter statusFilterList={statusFilterList} setStatusFilterList={setStatusFilterList}/>
			}
		},
		{
			dataField: 'exportEvents',
			text: t('documentation.columns.exportedto'),
			formatter: formatExportEvents,
			sortValue: formatExportEvents,
			filterValue: (cell, row) => cell.length == 0 ? 'unexported' : 'exported',
			filter: customFilter(),
			filterRenderer: (onFilter, column) => {
				return <ExportedToFilter exportedToFilterList={exportedToFilterList} setExportedToFilterList={setExportedToFilterList}/>
			}
		}
	]

	return (
		<div>
			<div className="clearfix">
				<div className="float-left">
					<h2>
						{t('documentation.label')}
					</h2>
					{ max != -1 && count > max &&
						<div>
							<FontAwesomeIcon icon={faExclamationTriangle} style={{color: '#FF0000'}}/>
							&nbsp;
							{t('documentation.warning', { max: max, total: count })}
						</div>
					}
	            </div>
	            { authService.hasRoles(['ROLE_CREATE_DOCUMENTATION']) &&
		            <div className="float-right" style={{paddingLeft: '5px'}}>
						<Button variant="outline-dark" href="/documentation/create">
							<FontAwesomeIcon icon={faPlus}/> {t('crudtable.add')}
						</Button>
				    </div>
			    }
	            { authService.hasRoles(['ROLE_EXPORT_DOCUMENTATION_LIST']) &&
		            <div className="float-right" style={{paddingLeft: '5px'}}>
						<ExportButton/>
				    </div>
			    }
				<div className="float-right">
					<Dropdown>
						<Dropdown.Toggle variant="outline-dark">
							<FontAwesomeIcon icon={faGear}/>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu-right" style={{padding: '10px', width: '240px'}}>
							<div>
								<label>
									<input type="checkbox" checked={showHidden} onChange={(e) => toggleShowHidden()}/>
									&nbsp;
									{t('documentation.showhidden')}
								</label>
							</div>
							<div>
								Max antal:
								&nbsp;
								<select className="form-control" style={{display: 'inline', width: 'auto'}} value={max} onChange={e => changeMax(e.target.value)}>
									<option>500</option>
									<option>1000</option>
									<option>1500</option>
									<option>2000</option>
									<option value={-1}>{t('crudtable.all')}</option>
								</select>
							</div>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>
			{ templatesDownloaded &&
				<>
					<DelayedSearch query={query} setQuery={setQuery}/>
					<CrudTable
						service={documentationService}
						serviceParams={getServiceParams()}
						columns={columns}
						sort={{dataField: sort, order: order}}
						editable={false}
						creatable={false}
						deletable={roles.includes('ROLE_DOCUMENTATION_WRITE')}
						searchable={false}
					/>
				</>
			}
		</div>
	)
}

export default DocumentationList;