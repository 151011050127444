import api from './api.service'
import axios from "axios"
import { SERVER_URL } from '../config'

export const tempFileService = {
	list,
	_delete,
    upload,
    getUploadLaterTasks,
    getInfo
};

function list() {
    return api.get('/tempFiles/')
}

function _delete(id) {
    return api.delete('/tempFiles/' + id)
}

function upload(photos, token) {
    return axios.post(SERVER_URL + '/tempFile/upload?token=' + token, photos)
}

function getUploadLaterTasks(token) {
	return api.get('/tempFile/getUploadLaterTasks?token=' + token)
}

function getInfo(token) {
	return api.get('/tempFile/getInfo?token=' + token)
}
