import api from './api.service'

export const referencePhotoService = {
    list,
    getAll,
    save,
    update,
    _delete
};

const BASE_URL = '/referencePhotos'

function list(max) {
    return api.get(BASE_URL, {max : max || 100})
}

function getAll() {
    return api.get('/referencePhoto/getAll')
}

function save(referencePhoto) {
    return api.post(BASE_URL, referencePhoto)
}

function update(id, referencePhoto) {
    return api.put(BASE_URL + "/" + id, referencePhoto)
}

function _delete(id) {
    return api.delete(BASE_URL + "/" + id)
}
