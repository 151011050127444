import React, { useEffect, useState } from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { authService } from '../../_services'
import { history } from '../../_helpers';
import Select from 'react-select'

function SwitchCustomer() {

	const [options, setOptions] = useState([]);
    const {t} = useTranslation('common');

    useEffect(() => {
		authService.getSwitchOptions().then(response => {
			setOptions(response)
		})
    }, [])

    const onClick = (userId) => {
        if (userId == authService.getCurrentUser().id) {
            history.push("/documentation")
        } else {
            authService.switchCustomer(userId).then(response => {
				history.push("/documentation")
			})
        }
    }

	return (
		<Container>
			<Row>
				<Col md="12">
					<h2 style={{marginTop: 40, marginBottom: 20}}>
						{t('switchcustomer.label')}
					</h2>
		            { options.map(option => {
		                return <div key={option.id} style={{marginBottom: 5}}>
		                    <Button variant="outline-primary" onClick={e => onClick(option.id)} style={{minWidth: 240}}>
			                    {option.name}
			                </Button>
		                </div>
		            })}
	            </Col>
			</Row>
        </Container>
    )
}

export default SwitchCustomer;