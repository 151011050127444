import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { alertActions } from '../../_actions/alert.actions'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import EditButton from './editbutton';
import DeleteButton from './deletebutton';
import AddButton from './addbutton';
import {Spinner} from 'react-bootstrap'
import { useTranslation } from "react-i18next";

function CrudTable({service, serviceParams, initialState, form, columns, onTableChange = () => console.log("onTableChange"),
		onSave, creatable = true, editable = true, deletable = true, searchable = true, sort, modalSize = 'md', rowStyle,
		onAddClick, onEditClick, buttons = []}, remote = { filter: false, search: false, pagination: false }) {
	const [items, setItems] = useState([])
	const [columnState, setColumns] = useState(columns)
	const dispatch = useDispatch()
	const {t} = useTranslation('common')
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		columns.forEach(column => {
			if (column.sort && !column.onSort) {
				column.onSort = onSort
			}
		})

		if (editable || deletable) {
            setColumns([
				...columns,
				{
					dataField: "edit",
					text: "",
					sort: false,
					formatter: editFormatter,
					headerAttrs: { width: (editable && deletable) ? 85 : 40 },
					attrs: { width: (editable && deletable) ? 85 : 40, className: "EditRow" }
				}
			])
		} else {
			setColumns([...columns])
		}

		getAll()
	}, [columns])

    const NoDataIndication = () => (
	    <div>
	        {loading &&
			    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'50px', marginBottom:'50px'}}>
					<Spinner animation="border" role="status">
						<span className="sr-only">Loading...</span>
					</Spinner>
			    </div>
		    }
		    {!loading &&
		        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'10px', marginBottom:'10px'}}>
	                <span>Ingen data hittades</span>
	            </div>
		    }
	    </div>
    )

    const editFormatter = (cell, row, rowIndex, formatExtraData) => {
		return (
			<span style={{ textAlign: "center", cursor: "pointer", lineHeight: "normal" }}>
				{ editable &&
					<EditButton
						data={row}
						confirmClick={onUpdate}
						form={form}
						size={modalSize}
						onClick={onEditClick}
					/>
				}
				{ editable && deletable &&
					<span>&nbsp;&nbsp;</span>
				}
				{ deletable &&
					<DeleteButton
						data={row}
						confirmClick={onDelete}
					/>
				}
			</span>
		)
	}
  
	const getAll = () => {
		setLoading(true)
		service.list(serviceParams).then(data => {
			setItems(data)
			setLoading(false)
		})
	}
  
    const onUpdate = row => {
		service.update(row.id, row).then( result => {
            dispatch(alertActions.success(t('alerts.updatesuccessful')))
            getAll()
//            setItems( prevItems => prevItems.map(item => {
//                if (item.id === row.id) {
//                    return row;
//                }
//                return item;
//            }))
        })
    }
  
	function onDelete(row) {
		service._delete(row.id).then(data => {
            dispatch(alertActions.success(t('alerts.deletesuccessful')))
			setItems( prevItems => prevItems.filter(item => {
			    return item.id !== row.id
			}))
		})
    }
  
    function onAdd(row) {
		service.save(row).then(data =>  {
			dispatch(alertActions.success(t('alerts.createsuccessful')))
			//const newItems = items.concat(data)
			//setItems(newItems)
			getAll()
			if (onSave) {
				onSave(data)
			}
		})
    }

    const pagination = paginationFactory({
		sizePerPage: 25,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		onPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		onSizePerPageChange: function (page, sizePerPage) {
			console.log('page', page);
			console.log('sizePerPage', sizePerPage);
		},
		paginationTotalRenderer: (from, to, size) => {
			return ' ' + t('crudtable.total', {from: from, to: to, size: size})
		},
		nextPageTitle: t('crudtable.next'), // the title of next page button
		prePageTitle: t('crudtable.previous'), // the title of previous page button
		firstPageTitle: t('crudtable.first'), // the title of first page button
		lastPageTitle: t('crudtable.last')
    })

    const { SearchBar } = Search;

    const MySearch = (props) => {
        let input

        useEffect(() => {
			const url = new URL(window.location.href);
			var q = url.searchParams.get('q')
			if (q) {
				props.onSearch(q)
				input.value = q
			} else {
				props.onSearch("")
			}
        }, [])

        const onKeyUp = e => {
            props.onSearch(input.value)

            const url = new URL(window.location.href);
            if (input.value) {
                url.searchParams.set('q', input.value);
            } else {
                url.searchParams.delete('q')
            }
			window.history.replaceState(null, '', url.toString());
        }
        return (
            <label className="search-label">
	            <input
	                className="form-control"
	                ref={ n => input = n }
			        type="text"
			        onKeyUp={onKeyUp}
			        placeholder={props.placeholder}
	            />
            </label>
        )
	}

	const onSort = (field, order) => {
		const url = new URL(window.location.href)
        url.searchParams.set('sort', field)
        url.searchParams.set('order', order)
		window.history.replaceState(null, '', url.toString())
	}

	const getSort = () => {
		const url = new URL(window.location.href)
		var urlSort = url.searchParams.get('sort')
		var urlOrder = url.searchParams.get('order')
		if (urlSort && urlOrder) {
			return {dataField: urlSort, order: urlOrder}
		} else if (sort) {
			return sort
		}
		return {}
	}

	return (
        <div>
			<ToolkitProvider
				bootstrap4
				keyField='id'
				data={items}
				columns={columnState}
				search
			>
                { toolkitprops => (
                    <div>
                        { searchable &&
                            <MySearch {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
                            //<SearchBar {...toolkitprops.searchProps} placeholder={t('crudtable.search')} />
                        }
						{ creatable &&
							<>
								<AddButton
									data={initialState}
									confirmClick={onAdd}
									form={form}
									size={modalSize}
									onClick={onAddClick}
								/>
								{ buttons.map(button => button) }
							</>
						}
                        <BootstrapTable
							remote={remote}
							noDataIndication={ () => <NoDataIndication /> }
							condensed={true}
							pagination={pagination}
							{...toolkitprops.baseProps}
							onTableChange={onTableChange}
							sort={getSort()}
							rowStyle={rowStyle}
							filter={filterFactory()}
                        />
                    </div>
                )}
	        </ToolkitProvider>
        </div>
    )
}

export default CrudTable

