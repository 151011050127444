import api from './api.service'
import axios from "axios"
import { SERVER_URL } from '../config'

export const uploadLaterService = {
    upload,
    getDocumentation,
    getTasks,
    getReferencePhotos
};

function upload(files, token) {
    return axios.post(SERVER_URL + '/uploadLater/upload?token=' + token, files)
}

function getDocumentation(token) {
	return api.get('/uploadLater/getDocumentation?token=' + token)
}

function getTasks(token) {
	return api.get('/uploadLater/getTasks?token=' + token)
}

function getReferencePhotos(token) {
	return api.get('/uploadLater/getReferencePhotos?token=' + token)
}
