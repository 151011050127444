import React from 'react';
import CrudTable from '../table/crudtable'
import { licenseService } from '../../_services'
import LicenseForm from './licenseform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'

function License() {

	const {t} = useTranslation('common');

	const initialState = {
		customerId: -1,
		name: '',
		licenseCode: '',
		logo: '',
		reverseDocumentationCreation: false,
		pauseDocumentations: false,
		tempFiles: false,
		demo: false,
		deletePhotosAfterUpload: 'UNFORCED',
		backupPhotosToLibrary: 'UNFORCED',
		expireDate: ''
	}

    const formatBoolean = function(cell) {
		return cell ? t('crudtable.yes') : t('crudtable.no')
    }

    const formatDate = function(cell) {
        if (cell) {
            return moment(cell).format('yyyy-MM-DD HH:mm')
        }
		return '-'
	}

    const columns = [
	    {
			dataField: 'customerName',
			text: t('license.columns.customer'),
			sort: true
	    },
	    {
			dataField: 'name',
			text: t('license.columns.name'),
			formatter: (cell) => cell || '-',
			sort: true
	    },
	    {
			dataField: 'licenseCode',
			text: t('license.columns.licensecode'),
			sort: true
	    },
	    {
			dataField: 'reverseDocumentationCreation',
			text: t('license.columns.reversedocumentationcreation'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'pauseDocumentations',
			text: t('license.columns.pausedocumentations'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'tempFiles',
			text: t('license.columns.tempfiles'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'demo',
			text: t('license.columns.demo'),
			formatter: formatBoolean,
			sort: true
	    },
	    {
			dataField: 'expireDate',
			text: t('license.columns.expiredate'),
			formatter: formatDate,
			sort: true
	    }
	]

    return (
		<div>
            <h2>
                {t('license.label')}
            </h2>
            <CrudTable
		        service={licenseService}
		        columns={columns}
		        initialState={initialState}
		        form={<LicenseForm />}
		        sort={{dataField: 'customerName', order: 'asc'}}
		        modalSize="lg"
            />
        </div>
    )
}

export default License;