import React, {useState} from 'react'
import { Button, Modal, Row, Col, Form, Alert } from 'react-bootstrap'
import { photoService } from '../../_services'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faRotateRight, faRotateLeft } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from "react-i18next";
import { SERVER_URL } from '../../config';

function EditPhotoButton(props) {

	const [state, setState] = useState(props.data)
	const [validated, setValidated] = useState(false);
    const [show, setShow] = useState(false)
    const [backdrop, setBackdrop] = useState(false);
    const {t} = useTranslation('common');

    const onClick = () => {
        setState(props.data)
        setValidated(false)
        setShow(true)
    }

    const handleConfirm = () => {
        handleClose()

        var data = {
            id: state.id,
            name: state.name,
            comment: state.comment,
            rotation: state.rotation
        }

        photoService.update(state.id, data).then(result => {
            props.onUpdate(result)
        })
    }

    const handleSubmit = (event) => {
	    event.preventDefault()
	    const form = event.currentTarget
	    if (form.checkValidity() === false) {
	        event.stopPropagation()
	        setValidated(true)
	    } else {
	        handleConfirm()
			setValidated(false)
	    }
	}

    const handleClose = () => {
		setShow(false)
		document.body.className = ""
		document.body.style = '';
    }

    const handleChange = (e) => {
	    const target = e.target
	    const name = target.name
	    const value = target.type === 'checkbox' ? target.checked : target.value
	    setState( prevState => ({
	        ...prevState,
	        [name]: value
	    }));
	}

	const rotate = (direction) => {
		var rotation = state.rotation || 0
	    if (direction == "left") {
	        var newRotation = rotation - 1
	        while (newRotation < 0) {
	            newRotation += 4
	        }
	        rotation = newRotation
	    } else {
			rotation = (rotation + 1) % 4
	    }
	    setState( prevState => ({
	        ...prevState,
	        rotation: rotation
	    }));
	}

    return (
        <>
	        <Button
	            onClick={onClick}
	            title={t('crudtable.edit')}
	            size="sm"
	            color="info"
	            variant="outline-secondary">
	            <FontAwesomeIcon icon={faEdit}/>
	        </Button>
	        <Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={show}
				backdrop={backdrop}
				onShow={() => console.log('onShow')}
				onHide={handleClose}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>{t('crudtable.edit')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="data-form" noValidate onSubmit={handleSubmit} validated={validated}>
						<Form.Group controlId="name">
			                <Form.Label>
			                    {t('photo.columns.name')}
			                </Form.Label>
			                <Form.Control required type="text" name="name" value={state.name} onChange={handleChange} />
			            </Form.Group>
			            <Form.Group controlId="comment">
			                <Form.Label>
			                    {t('photo.columns.comment')}
			                </Form.Label>
			                <Form.Control type="text" name="comment" value={state.comment} onChange={handleChange} />
			            </Form.Group>
			            <Form.Group controlId="rotate">
			                <Form.Label>
			                    {t('photo.columns.rotate')}
			                </Form.Label>
			                <br/>
			                { state.rotation &&
				                <Alert variant="danger">
					                {t('photo.rotationwarning')}
				                </Alert>
			                }
			                <div style={{width: 300, height: 300, transform: 'rotate(' + (90 * (state.rotation || 0)) + 'deg)'}}>
			                    <img src={SERVER_URL + '/photo/thumbnail/' + state.id + '?t=' + state.lastUpdated} style={{maxWidth: 300, maxHeight: 300}}/>
			                </div>
			                <br/>
			                <Button variant="outline-secondary" onClick={e => rotate("left")}>
								<FontAwesomeIcon icon={faRotateLeft}/>
							</Button>
							<Button variant="outline-secondary" onClick={e => rotate("right")}>
								<FontAwesomeIcon icon={faRotateRight}/>
							</Button>
			            </Form.Group>
		            </Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						{t('crudtable.cancel')}
					</Button>
					<Button form='data-form' type="submit" variant="primary">
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
        </>
    )
}

export default EditPhotoButton