import React, { useState, useEffect } from 'react';
import { Button, Modal, Form, Alert } from 'react-bootstrap'
import CrudTable from '../table/crudtable'
import { documentationTagConfigurationService, customerService } from '../../_services'
import DocumentationTagConfigurationForm from './documentationtagconfigurationform.component'
import { useTranslation } from "react-i18next";
import moment from 'moment'
import { SERVER_URL } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileImport } from '@fortawesome/free-solid-svg-icons'

function DocumentationTagConfiguration() {

	const [showImportModal, setShowImportModal] = useState(false)
	const [customers, setCustomers] = useState([])
	const [selectedCustomer, setSelectedCustomer] = useState(-1)
	const [imports, setImports] = useState([])
	const {t} = useTranslation('common');

	useEffect(() => {
		customerService.getOptions().then(customers => {
            customers.sort((a, b) => a.name.localeCompare(b.name))
            setCustomers(customers)
            if (selectedCustomer === -1 && customers.length > 0) {
                setSelectedCustomer(customers[0].id)
            }
        })
    }, []);

	const initialState = {
		customerId: -1,
		name: '',
		tag: ''
	}

	const columns = [
		{
			dataField: 'customerName',
			text: t('documentationtagconfiguration.columns.customer'),
			sort: true
		},
		{
			dataField: 'name',
			text: t('documentationtagconfiguration.columns.name'),
			sort: true
		},
		{
			dataField: 'tag',
			text: t('documentationtagconfiguration.columns.tag'),
			sort: true
		}
	]

	const onClick = () => {
        setShowImportModal(true)
    }

    const onHide = () => {
		setShowImportModal(false)

		setSelectedCustomer(customers[0].id)
        setImports([])
    }

    const handleCustomerChange = e => {
        setSelectedCustomer(e.target.value)
        imports.forEach(row => {
            row.customerId = e.target.value
        })
    }

    const handleFileChange = e => {
        console.log(e)

        let file = e.target.files[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener('load', (event) => {
                var result = reader.result
                var rows = result.split(/\r?\n/)
                var imports = []
                rows.forEach(row => {
                    if (row) {
						var rowSplit = row.split(';')
						if (rowSplit.length == 2) {
							var documentationTagConfiguration = {
								customerId: selectedCustomer,
								name: rowSplit[0],
								tag: rowSplit[1]
							}
							imports.push(documentationTagConfiguration)
						}
                    }
                })
                setImports(imports)
            });
            reader.readAsText(file)
        }
    }

    const onSubmit = () => {
        console.log('onSubmit')

        let data = {
            list: imports
        }

        documentationTagConfigurationService.importCSV(data).then(response => {
            console.log(response)

            onHide()
        })
    }

	return (
		<div>
			<h2>
				{t('documentationtagconfiguration.label')}
            </h2>
            <CrudTable
		        service={documentationTagConfigurationService}
		        columns={columns}
		        initialState={initialState}
		        form={<DocumentationTagConfigurationForm />}
		        sort={{dataField: 'customerName', order: 'asc'}}
		        modalSize="md"
		        buttons={[<Button
		                key="import"
			            onClick={onClick}
			            title="Importera"
			            color="info"
			            variant="outline-dark"
			            style={{float: 'right', marginRight: 5}}>
			            Importera <FontAwesomeIcon icon={faFileImport}/>
			        </Button>]}
			/>
			<Modal
				style={{overflow: 'scroll'}}
				size="md"
				show={showImportModal}
				backdrop={showImportModal}
				onShow={() => console.log('onShow')}
				onHide={onHide}
				animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						Importera dokumentationstaggar
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form.Label>
		                Kund
	                </Form.Label>
					<Form.Control required as="select" name="selectedCustomer" value={selectedCustomer} onChange={handleCustomerChange}>
	                    { customers.map(customer => (
	                        <option key={customer.id} value={customer.id}>
	                            {customer.name}
	                        </option>
	                    ))}
	                </Form.Control>
	                <Form.Label>
		                Fil
	                </Form.Label>
					<Form.Control required={true} type="file" accept=".csv" name="file" onChange={handleFileChange} />
					<Alert variant="primary" style={{marginTop: 5}}>
		                <b>Kolumner:</b> name;tag
	                </Alert>
					<Form.Label style={{marginTop: 5}}>
		                Nya dokumentationstaggar
	                </Form.Label>
	                <div style={{maxHeight: 400, overflowY: 'auto'}}>
		                <table className="table table-bordered">
		                    <thead>
		                        <tr>
		                            <th>
		                                Namn
		                            </th>
		                            <th>
		                                Tagg
		                            </th>
		                        </tr>
		                    </thead>
		                    <tbody>
		                        { imports.map((row, index) => {
		                            return <tr key={index}>
			                            <td>
			                                { row.name }
			                            </td>
			                            <td>
			                                { row.tag }
			                            </td>
			                        </tr>
		                        })}
		                    </tbody>
		                </table>
	                </div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={onHide}>
						{t('crudtable.cancel')}
					</Button>
					<Button variant="primary" onClick={onSubmit}>
						{t('crudtable.ok')}
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
    )
}

export default DocumentationTagConfiguration;