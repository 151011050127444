import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Row, Col, Form, Alert, Spinner } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import { tempFileService } from '../../_services'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

function UploadFiles() {

	const [documentationName, setDocumentationName] = useState(null)
	const [templateName, setTemplateName] = useState(null)
	const [creator, setCreator] = useState(null)
	const [photoFiles, setPhotoFiles] = useState([])
	const [showSuccessAlert, setShowSuccessAlert] = useState(false)
	const [showErrorAlert, setShowErrorAlert] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const queryParameters = new URLSearchParams(window.location.search)
	const token = queryParameters.get("token")
	const {t} = useTranslation('common')
	const hiddenFileInput = useRef(null)

    useEffect(() => {
		tempFileService.getInfo(token)
		.then(result => {
			setDocumentationName(result.documentationName)
			setTemplateName(result.templateName)
			setCreator(result.creator)
        })
        .catch(error => {
			console.log(error)
		})
    }, [])

    const handleFilesChange = (e) => {
        let files = e.target.files
        if (files && files.length > 0) {
            var promises = []
            for (var i = 0; i < files.length; i++) {
                let file = files[i]
                let promise = new Promise((resolve, reject) => {
                    let fileReader = new FileReader()
                    fileReader.onload = function() {
                        let binaryString = fileReader.result;
                        let hexString = btoa(binaryString)
                        console.log(file)
                        var externalFile = {
							name: file.name,
							size: file.size,
							data: hexString,
							type: file.type
						}
                        resolve(externalFile)
                    }

                    fileReader.onerror = function() {
                        reject(fileReader)
                    }

                    fileReader.readAsBinaryString(file)
                })
                promises.push(promise)
            }

            Promise.all(promises).then(values => {
                var newExternalFiles = [...photoFiles]
                values.forEach(value => {
                    if (!newExternalFiles.some(externalFile => externalFile.name == value.name)) {
                        newExternalFiles.push(value)
                    }
                })
                setPhotoFiles(newExternalFiles)
            })
        }
    }

    const removeExternalFile = (e, externalFile) => {
        e.preventDefault()

        var newExternalFiles = photoFiles.filter(otherExternalFile => otherExternalFile != externalFile)
        setPhotoFiles(newExternalFiles)
    }

    const upload = () => {
        setShowSuccessAlert(false)
        setShowErrorAlert(false)

        if (photoFiles.length == 0) {
            return
        }

        setIsUploading(true)

		tempFileService.upload(photoFiles, token)
		.then(result => {
			setPhotoFiles([])
			setShowSuccessAlert(true)
			setIsUploading(false)
        })
        .catch(error => {
			setShowErrorAlert(true)
			setIsUploading(false)
		})
    }

	return (
		<Container>
			{ token &&
				<Row>
					{ showSuccessAlert &&
						<Col sm="12">
							<Alert style={{marginTop: '10px'}} variant="success" dismissible onClose={() => setShowSuccessAlert(false)}>
				                {t('uploadfiles.success')}
				            </Alert>
			            </Col>
					}
					{ showErrorAlert &&
						<Col sm="12">
							<Alert style={{marginTop: '10px'}} variant="danger" dismissible onClose={() => setShowErrorAlert(false)}>
				                {t('uploadfiles.error')}
				            </Alert>
			            </Col>
					}
					{ documentationName &&
						<>
							<Col md="12">
								<h2>
									{documentationName}
									{ templateName ? ' (' + templateName + ')' : '' }
								</h2>
				            </Col>
				            { creator &&
								<Col md="12">
									<h4>
										{t('uploadfiles.creator')}: {creator}
									</h4>
					            </Col>
							}
							<Col md="12">
								<hr/>
							</Col>
						</>
					}
					<Col md="12">
						<h3>
							{t('uploadfiles.label')}
						</h3>
		            </Col>
					<Col md="12">
						<Form.Group controlId="files">
							<Button variant="outline-primary" onClick={() => hiddenFileInput.current.click()}>
								{t('uploadfiles.choose')}
							</Button>
							<div style={{display: 'none'}}>
								<Form.Control ref={hiddenFileInput} type="file" multiple name="files" onChange={handleFilesChange}/>
							</div>
			            </Form.Group>
		            </Col>
		            { photoFiles.map(externalFile => {
						return <Col xs="12" sm="6" md="3" key={externalFile.name} style={{paddingBottom: '10px'}}>
							<div style={{width: '100%'}}>
								<div style={{display: 'inline-block', whiteSpace: 'nowrap', overflow: 'hidden', width: 'calc(100% - 40px)'}}>
									{externalFile.name}
								</div>
								<div style={{display: 'inline-block'}}>
									<Button variant="outline-danger" onClick={e => removeExternalFile(e, externalFile)}>
										<FontAwesomeIcon icon={faTrash} style={{color: 'red'}}/>
									</Button>
								</div>
							</div>
							{ externalFile.type == 'image/jpeg' &&
								<img src={'data:image/jpeg;base64, ' + externalFile.data} style={{width: '100%'}}/>
							}
						</Col>
					})}
					<Col sm="12" style={{marginBottom: 20}}>
						{ !isUploading &&
							<Button variant="primary" onClick={upload}>
								{t('uploadfiles.upload')}
							</Button>
						}
						{ isUploading &&
							<Button variant="primary" disabled>
								{t('uploadfiles.uploading')}
								&nbsp;
					            <Spinner animation="border" role="status" size="sm">
									<span className="sr-only">{t('uploadfiles.uploading')}...</span>
								</Spinner>
							</Button>
						}
					</Col>
	            </Row>
            }
        </Container>
    )
}

export default UploadFiles;